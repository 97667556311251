export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [0];

export const dictionary = {
		"/": [3],
		"/admin": [~4,[2]],
		"/admin/announcements": [5,[2]],
		"/admin/class-schedule": [6,[2]],
		"/admin/classes": [7,[2]],
		"/admin/dashboard": [8,[2]],
		"/admin/locations": [9,[2]],
		"/admin/orders": [10,[2]],
		"/admin/products": [11,[2]],
		"/admin/users": [12,[2]],
		"/admin/workshops": [13,[2]],
		"/auth/reset/[token]": [14],
		"/checkout": [15],
		"/classes": [16],
		"/classes/[locationId]": [17],
		"/confirmation": [18],
		"/forgot": [19],
		"/history": [20],
		"/leta": [21],
		"/login": [~22],
		"/merge": [23],
		"/privacy": [24],
		"/profile": [~25],
		"/register": [~26],
		"/reserve": [~27],
		"/shynet": [28],
		"/teachers": [29],
		"/teachertraining": [30],
		"/terms": [31],
		"/workshops": [32]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';